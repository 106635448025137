var allRows;
var genericErrorMessage = "Error! Please contact legendarylearning@gallaghercommunication.com";
var codeIndexExceededMessage = "Error! There was an error retreiving your unique code. Please contact legendarylearning@gallaghercommunication.com";
      
$(document).ready(function() {
console.log('ready');
  $.ajax({
    url: './data/codes.csv',
    dataType: 'text',
  }).done(successFunction);

  // hide iOS button and label
  $("#codeOutput").toggle( false );
  $("#iosInfo").toggle( false );
  $("#androidInfo").toggle( false );

});



$( "#iosButton" ).click(function(e) {
  // prevent browser attempt to navigate to URL
  e.preventDefault();

  $.getJSON("https://api.countapi.xyz/hit/legendarylearning.gallaghercommunication.com/nw1", function(response) {

      // hide iOS button and label
      hideUI();
      
      $("#codeOutput").toggle( true );
      $("#iosInfo").toggle( true );
      const index = response.value-1;

      if( typeof response === "undefined" || index >= allRows.length )
      {
        alert(codeIndexExceededMessage);
        return;
      }

      const item = allRows[ index ];
      const code = item.split(",")[0];
      const url = item.split(",")[1];

      var urlButton = "<a target='_blank' href='" + url +  "' class='btn btn-primary btn-lg px-4'>Install iOS App</a>";
      var urlLink = "<a target='_blank' href='" + url +  "' class=''>" + url + "</a>";
      $("#newButton").html(urlButton );
      $("#codeOutputTF").html(code );
      //$("#urlOutput").html(urlLink );

      // click handler added here as the element is appended at this point
      $( "#clipButton" ).click(function(e){
      // prevent browser attempt to navigate to URL
        e.preventDefault();
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($('#codeOutput').find('#codeOutputTF').text()).select();
        document.execCommand("copy");
        $temp.remove();
      });
  });
});

$( "#androidButton" ).click(function(e) {
  // prevent browser attempt to navigate to URL
  e.preventDefault();
   // hide iOS button and label
  hideUI();
  $("#androidInfo").toggle( true );
});

function hideUI()
{
  $("#iosInstrux").toggle( false );
  $("#androidInstrux").toggle( false );
  $("#iosInstrux").toggle( false );
  $("#androidInstrux").toggle( false );
  $("#intro").toggle( false );
  $("hr").toggle( false );
}

function successFunction(data) {

  allRows = data.split(/\r?\n|\r/);        
}

$(window).on("error", function(evt) {
    alert(genericErrorMessage);
});
